import runtimeConfig from 'libs/runtime-config';

const flags = JSON.parse(runtimeConfig.REACT_APP_OKA_PUBLIC_FEATURE_FLAGS ?? '[]');

export const FEATURE_ODD_APPOINTMENT = 'odd-appointment';
export const FEATURE_TELEMEDICINE_CONSENT = 'telemedicine-consent-flow';
export const FEATURE_HIGO = 'feature-higo';
export const FEATURE_HIDE_PATIENT_UPLOAD_BUTTON = 'hide-patient-upload-button';
export const FEATURE_HIDE_DOCTOR_UPLOAD_BUTTON = 'hide-doctor-upload-button';
export const FEATURE_AUDIO_VIDEO_TRACK = 'feature-audio-video-track';

const isAvailable = (featureName = []) => {
  if (!Array.isArray(featureName)) {
    return false;
  }
  const match = flags.filter(f => f.isActive && featureName.includes(f.name)) || [];

  return match.length > 0;
};

export default isAvailable;
