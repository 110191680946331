import { useState, useCallback } from 'react';
import { AppointmentMeetingInfo, CopiedText } from './styles';

const CopyableMeetingInfo = ({ appointment, meetingId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const meetingDetailsText = `${appointment?.appointmentNumber}::${meetingId}`;

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(meetingDetailsText).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    });
  }, [meetingDetailsText]);

  return (
    <div onClick={copyToClipboard}>
      <AppointmentMeetingInfo $isCopied={isCopied}>{meetingDetailsText}</AppointmentMeetingInfo>
      <CopiedText>{isCopied ? 'Copied!' : ''}</CopiedText>
    </div>
  );
};

export default CopyableMeetingInfo;
