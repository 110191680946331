import { useMemo } from "react";
import { getQueryParams } from 'libs/url';

const useActions = ({
  data,
}) => {
  const queryParams = getQueryParams(window.location);
  const lang = queryParams?.lang || 'en';

  const formatMessages = useMemo(() => {
    const parseContent = JSON.parse(data?.text || "{}");
    return parseContent[lang] || [];
  }, [data, lang]);

  return {
    notes: formatMessages,
  };
};

export default useActions;
