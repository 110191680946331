import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 768px) {
    width: 36%;
    padding: 0 16px;
  }
`;

export const Space = styled.div`
  margin-top: 8px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;
