import { Fragment, useState, memo, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Colors from 'okadoc-component-ui/lib/Colors';
import AppointmentAPI from 'libs/api/appointment';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CheckCircle, Copy, Files, User, Eye } from 'phosphor-react';
import Png from 'components/Png';
import Loading from 'components/LoadingMessage';
import arePropsEqual from 'libs/arePropsEqual';
import { formatMessages } from 'libs/message';
import { FORMATS_MESSAGE, MESSAGE_SENDER } from 'libs/constant';
import useChat from 'libs/hooks/useChat';
import DocumentPreviewTray from 'components/molecules/document-preview-tray';
import ExamReportViewer from './exam-report-viewer';
import QuestionnaireNotes from './questionnaire-notes';

let theDate = day.extend(utc);
theDate = theDate.extend(advancedFormat);
theDate = theDate.extend(localizedFormat);
theDate = theDate.extend(relativeTime);

const ThumbnailDefault = () => (
  <div className="thumbnail-default">
    <User size={18} />
  </div>
);

export const ConsultationPrivacy = () => {
  const { t } = useTranslation();

  return (
    <div className="alert alert--transparent">
      <p>{t('Messages are end to end encrypted. No one outside of this chat can read or listen to them.')}</p>
    </div>
  );
};

export const ConsultationODDNotice = () => {
  const { t } = useTranslation();

  return (
    <div className="alert alert--transparent">
      <p>{t('Please note this is not for emergency. In case of emergency call 998.')}</p>
    </div>
  );
};

export const ConsultationLoading = () => {
  const { t } = useTranslation();

  return (
    <div className="alert alert--info">
      <p>{t('loading messages.')}</p>
    </div>
  );
};

export const SystemMessage = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className="chat-message">
      <div className="chat-message__profile-picture">
        <div className="thumbnail">
          <ThumbnailDefault />
        </div>
      </div>
      <div className="chat-message__content">
        <Fragment>
          <p className="chat-message__content-text">{t(message)}</p>
        </Fragment>
      </div>
    </div>
  );
};

export const DocumentSystemMessage = ({ data = {}, onViewDocument = () => { } }) => {
  const { meta } = data;
  const { document_title: documentName } = meta;

  return (
    <>
      <SystemMessage message="Document shared from hospital integration system" />
      <div className="chat-message">
        <div className="chat-message__profile-picture">
          <div className="thumbnail" />
        </div>
        <div className="chat-message__content doc-system-box flex-row justify-content-between align-items-center">
          <div className="chat-message__content-doc-system-name">
            <Png
              name="https://img.okadoc.com/photos/block_images/img/icon/od-pdf-blue.svg"
              className="img-responsive"
              alt={documentName}
              external
              width="20px"
            />
            <p className="chat-message__content-text">{documentName}</p>
          </div>
          <Eye size={20} color={Colors.primary.NavyBlue} style={{ cursor: 'pointer' }} onClick={onViewDocument} />
        </div>
      </div>
    </>
  );
};

const SessionJoinVideo = ({ isPatient, data }) => {
  const { t } = useTranslation();
  const isEventFromCurrentParticipant =
    (isPatient && data.sender === 'patient') ||
    (!isPatient && data.sender === 'doctor') ||
    (!isPatient && data.sender === 'practitioner');

  let text = t('{{sender}} has joined the video call', { sender: data.text });
  if (isEventFromCurrentParticipant) text = t('You have joined the video call');

  return (
    <div className="alert alert--transparent">
      <p>{text}</p>
      {!isEventFromCurrentParticipant && <p className="chat-message__content-info">{t('You are on the call')}</p>}
    </div>
  );
};

const ChatOnlyEnd = ({ isPatient, data, appointment }) => {
  const { t } = useTranslation();

  let person = data.sender === 'patient' ? data.patient : data.practitioner;
  const isEventFromCurrentParticipant =
    (isPatient && data.sender === 'patient') ||
    (!isPatient && data.sender === 'doctor') ||
    (!isPatient && data.sender === 'practitioner');

  if (!isPatient && data.sender === 'patient') person = appointment.patient;
  if (isPatient && (data.sender === 'practitioner' || data.sender === 'doctor')) person = appointment.practitioner;

  let text = t('You has left the teleconsultation');
  if (!isEventFromCurrentParticipant) text = t('{{person}} have left the teleconsultation', { person });

  return (
    <div className="alert alert--transparent">
      <p>{text}</p>
    </div>
  );
};

const ChatOnlyStart = ({ isPatient, data, appointment }) => {
  const { t } = useTranslation();
  let person = data.sender === 'patient' ? data.practitioner : data.patient;
  const isEventFromCurrentParticipant =
    (isPatient && data.sender === 'patient') ||
    (!isPatient && data.sender === 'doctor') ||
    (!isPatient && data.sender === 'practitioner');

  if (!isPatient && data.sender === 'patient') person = appointment.patient;
  if (isPatient && (data.sender === 'practitioner' || data.sender === 'doctor')) person = appointment.practitioner;

  let text = t('You have joined the teleconsultation');
  if (!isEventFromCurrentParticipant) text = t('{{person}} has joined the teleconsultation', { person });

  return (
    <div className="alert alert--transparent">
      <p>{text}</p>
    </div>
  );
};

const ImageMessageLoading = ({ isPatient, data, onRetryClick, t }) => {
  let leftChat = false;
  if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
    leftChat = true;
  }
  if (!data.filetype) {
    data.filetype = data.text.indexOf('.pdf') > -1 ? 'application/pdf' : 'image';
  }
  return (
    <div className={'chat-message ' + (leftChat ? 'current-user' : '')}>
      <div className="chat-message__content">
        <div className="chat-message__image">
          <div className="thumbnail thumbnail--square blur">
            <Png
              name={data.filetype === 'application/pdf' ? 'ic-file' : data.text}
              external={data.filetype !== 'application/pdf'}
              className="thumbnail__image"
              alt="attachment"
            />
            {data.retry && (
              <button onClick={() => onRetryClick(data)} className="button retry-button">
                {t('Retry')}
              </button>
            )}
          </div>
          {!data.retry && <Loading fullScreen={false} />}
        </div>
      </div>
    </div>
  );
};

const isInAppBrowser = process.env.REACT_APP_INAPP_BROWSER === 'true' ?? false;

const ImageMessage = ({
  isPatient,
  isODDConsultation = false,
  data,
  onOpenPhoto,
  onOpenPatientDocument = () => { },
  patientPicture,
  doctorPicture,
  onShowDocument = () => { }
}) => {
  let leftChat = false;
  if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
    leftChat = true;
  }
  const messageDate = theDate(data.time).local();
  const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
  const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm';

  if (!data.filetype) {
    data.filetype = data.text.indexOf('.pdf') > -1 ? 'application/pdf' : 'image';
  }

  const profilePicture = data.sender === 'patient' ? patientPicture : doctorPicture;
  const fileName = data?.text?.split('/').pop().split('?')[0].split('#')[0];

  // disabled
  // useEffect(() => {
  //   if (previewPdfRef.current && isInAppBrowser) {
  //     const onOpenPDF = () => {
  //       window.open(previewPdfRef.current.href, '_blank', 'noopener,noreferrer');
  //     };

  //     previewPdfRef.current.addEventListener('click', onOpenPDF);
  //     return () => previewPdfRef.current.removeEventListener('click', onOpenPDF);
  //   }
  // }, [previewPdfRef, isInAppBrowser]);

  const Image = ({ photo, downloadable }) => (
    <div className="thumbnail thumbnail--square">
      <Png
        name={downloadable ? 'ic-file' : photo}
        external={!downloadable}
        className="thumbnail__image"
        alt="attachment"
      />
    </div>
  );

  const imageActionContent = useMemo(() => {
    let componentContent = (
      <a href={data?.text} target="_blank" rel="noopener noreferrer" className="chat-message__image">
        <Image downloadable />
      </a>
    );

    if (isODDConsultation || isInAppBrowser) {
      componentContent = (
        <div onClick={() => onShowDocument(data.text, fileName)} className="chat-message__image">
          <Image downloadable />
        </div>
      );
    } else if (!isODDConsultation && FORMATS_MESSAGE.PATIENT_DOCUMENT === data.format) {
      componentContent = (
        <div onClick={() => onOpenPatientDocument(data.id)} className="chat-message__image">
          <Image downloadable />
        </div>
      );
    }

    return componentContent;
  }, [data.format, data.id, data.text, fileName, isODDConsultation, onOpenPatientDocument, onShowDocument]);

  const isPDF = data.filetype === 'application/pdf';
  return (
    <div className={'chat-message ' + (leftChat ? 'current-user' : '')}>
      {!leftChat && (
        <div className="chat-message__profile-picture">
          <div className="thumbnail">
            <Png
              external
              name={profilePicture}
              alt="profile picture"
              className="thumbnail__image"
              width="10px"
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.style.display = 'none';
              }}
            />
            <ThumbnailDefault />
          </div>
        </div>
      )}
      <div className="chat-message__content">
        {isPDF ? (
          imageActionContent
        ) : (
          <div className="chat-message__image" onClick={() => onOpenPhoto(data)}>
            <Image photo={data.text} />
          </div>
        )}
        <p className="chat-message__content-time">{messageDate.format(messageTimeFormat)}</p>
      </div>
      {leftChat && (
        <div className="chat-message__profile-picture chat-message__profile-picture--right">
          <div className="thumbnail">
            <Png
              external
              name={profilePicture}
              alt="profile picture"
              className="thumbnail__image"
              width="10px"
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.style.display = 'none';
              }}
            />
            <ThumbnailDefault />
          </div>
        </div>
      )}
    </div>
  );
};

const TextMessage = ({
  isPatient,
  isExamReport = false,
  data,
  doctorPicture,
  patientPicture,
  iconText = null,
  messageContentClassName = '',
  hideCopy = false,
  onClick = () => { }
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isHover) {
      setIsCopied(false);
    }
  }, [isHover]);

  const onClickCopyMessages = () => {
    navigator.clipboard.writeText(data.text);
    setIsCopied(true);
  };

  const onMouseHover = () => {
    if (!isPatient) {
      setIsHover(value => !value);
    }
  };

  let leftChat = false;
  let textTitle = data.text;

  if ((isPatient && data.sender === 'patient') || (!isPatient && data.sender !== 'patient')) {
    leftChat = true;
  }

  if (isExamReport) {
    /**
     * first condition coming from chat history response API and second condition coming from SSE
     * priority from response API first every user reload the page and
     * using SSE then when user standby in the chat room
     */
    textTitle = data?.meta?.document_title ?? data.text;
  }

  const profilePicture = data.sender === 'patient' ? patientPicture : doctorPicture;
  const chatContainerClass = classNames('chat-message', { 'current-user': leftChat });
  const profilePictureClass = classNames('chat-message__profile-picture', {
    'chat-message__profile-picture--right': leftChat,
    'order-0': !leftChat,
    'order-2': leftChat
  });
  const messageContentContainerClass = classNames('chat-message__content', 'order-1', {
    [messageContentClassName]: true
  });
  const messageContentTextClass = classNames('d-flex', {
    'align-items-center': iconText,
    'flex-row': iconText
  });
  const chatCopyClass = classNames('chat-message__content-copy', { 'order-2': !leftChat, 'order-0': leftChat });

  const messageDate = theDate(data.time).local();
  const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
  const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm';

  return (
    <div className={chatContainerClass} onMouseEnter={onMouseHover} onMouseLeave={onMouseHover} onClick={onClick}>
      <div className={profilePictureClass}>
        <div className="thumbnail">
          <Png
            external
            name={profilePicture}
            alt="profile picture"
            className="thumbnail__image"
            width="10px"
            onError={e => {
              e.target.onerror = null;
              e.target.style.display = 'none';
            }}
          />
          <ThumbnailDefault />
        </div>
      </div>
      <div className={messageContentContainerClass}>
        <div className={messageContentTextClass}>
          {iconText && <div className="d-flex align-items-center mr-10">{iconText}</div>}
          <div className="chat-message__content-text">{textTitle}</div>
        </div>
        <div className="chat-message__content-time">{messageDate.format(messageTimeFormat)}</div>
      </div>
      {isHover && !hideCopy && (
        <div className={chatCopyClass} onClick={onClickCopyMessages}>
          {isCopied ? (
            <CheckCircle size={24} color={Colors.primary.NiagaraGreen} weight="fill" />
          ) : (
            <div className="d-flex align-items-center" title="Copy">
              <Copy color={Colors.primary.NavyBlue} size={24} weight="light" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const VideoEndEventMessage = ({ data, isPatient, appointment, t }) => {
  let person = data.sender === 'patient' ? data.practitioner : data.patient;

  let text = t('You have left the video call');

  if (
    (!isPatient && data.sender === 'patient') ||
    (isPatient && (data.sender === 'practitioner' || data.sender === 'doctor'))
  ) {
    if (!isPatient && data.sender === 'patient') person = appointment.patient;
    if (isPatient && (data.sender === 'practitioner' || data.sender === 'doctor')) person = appointment.practitioner;
    text = t('{{person}} has left the video call. ', { person });
  }

  return (
    <div className="alert alert--transparent">
      <p>{text}</p>
    </div>
  );
};

const SessionEndEventMessage = ({ data }) => {
  const { t } = useTranslation();
  const messageDate = theDate(data.time).local();
  const isToday = messageDate.format('YYYY-MM-DD') === theDate().local().format('YYYY-MM-DD');
  const messageTimeFormat = isToday ? 'HH:mm' : 'Do, MMM YYYY HH:mm';
  return (
    <div className="alert alert--transparent alert--full rounded-0">
      <p>
        <small>
          {t('Consultation ended by {{sender}} at ', { sender: data.sender })}
          <strong>{messageDate.format(messageTimeFormat)}</strong>
        </small>
      </p>
    </div>
  );
};

const MessageList = ({
  appointment,
  isPatient,
  messages = [],
  onOpenPhoto,
  onRetryClick,
  t,
  doctorPicture,
  patientPicture
}) => {
  const [showTray, setShowTray] = useState(false);
  const [showExamReportViewer, setShowExamReportViewer] = useState(false);
  const [file, setFile] = useState({
    url: '',
    fileName: '',
    messageId: ''
  });

  const {
    value: {
      appointment: { isODDConsultation }
    }
  } = useChat();

  useEffect(() => {
    if (!showTray) {
      setFile({ url: '', fileName: '', messageId: '' });
    }
  }, [showTray]);

  const onCloseExamReportViewer = () => setShowExamReportViewer(false);

  const onCloseTray = () => setShowTray(false);

  const onShowDocument = (url, fileName) => {
    setShowTray(true);
    setFile({ ...file, url, fileName });
  };

  // Handle action for viewing document which uploaded by system
  const onViewDocument = useCallback(async chatHistoryId => {
    if (chatHistoryId) {
      const response = await fetchChatHistory(chatHistoryId);

      if (response) {
        const data = response?.data?.data?.[0] ?? {};
        onShowDocument(data?.text, data?.meta?.document_title);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenPatientDocument = useCallback(async chatHistoryId => {
    if (chatHistoryId) {
      const response = await fetchChatHistory(chatHistoryId);

      if (response) {
        window.open(response?.data?.data?.[0]?.text, '_blank', 'noopener noreferrer');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenExamReportViewer = useCallback(async ({ examReportName, chatHistoryId, isExamReport = false }) => {
    if (chatHistoryId && isExamReport) {
      setShowExamReportViewer(true);
      setFile({ ...file, fileName: examReportName, messageId: chatHistoryId });
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchChatHistory = async chatHistoryId => {
    // eslint-disable-next-line new-cap
    const [, response] = await AppointmentAPI.GetChatHistory({
      appointmentNumber: appointment.appointmentNumber,
      chatId: chatHistoryId
    });

    return response;
  };

  const localMessages = formatMessages(messages);

  return (
    <>
      {(localMessages || []).map((message, i) => {
        const { format, sender } = message;
        const isExamReport = format === FORMATS_MESSAGE.EXAM_REPORT;
        let documentId = message?.id;

        if ((sender === MESSAGE_SENDER.SYSTEM && format === FORMATS_MESSAGE.PATIENT_DOCUMENT) || isExamReport) {
          // first condition is coming from SSE and second condition from chat-history
          documentId = message?.chatHistoryId ?? message?.id;
        }
        return (
          <Fragment key={`${message.id}-${i}`}>
            {[FORMATS_MESSAGE.QUESTIONNAIRE_DATA].includes(format) && (
              <QuestionnaireNotes data={message} />
            )}
            {[FORMATS_MESSAGE.TEXT, FORMATS_MESSAGE.EXAM_REPORT].includes(format) && (
              <TextMessage
                isPatient={isPatient}
                data={message}
                doctorPicture={doctorPicture}
                patientPicture={patientPicture}
                iconText={
                  isExamReport && (
                    <Files
                      color={isPatient ? Colors.text.WhiteText : Colors.primary.NavyBlue}
                      size={20}
                      weight="light"
                    />
                  )
                }
                isExamReport={isExamReport}
                hideCopy={isExamReport}
                messageContentClassName={isExamReport ? 'cursor-pointer' : ''}
                onClick={() =>
                  onOpenExamReportViewer({
                    // first condition coming from chat history response API and second condition coming from SSE
                    examReportName: message?.meta?.document_title ?? message?.text,
                    chatHistoryId: documentId,
                    isExamReport
                  })
                }
              />
            )}
            {[FORMATS_MESSAGE.IMAGE, FORMATS_MESSAGE.PATIENT_DOCUMENT].includes(format) &&
              sender !== MESSAGE_SENDER.SYSTEM && (
                <ImageMessage
                  isPatient={isPatient}
                  isODDConsultation={isODDConsultation}
                  data={message}
                  onOpenPhoto={onOpenPhoto}
                  onOpenPatientDocument={onOpenPatientDocument}
                  onShowDocument={onShowDocument}
                  doctorPicture={doctorPicture}
                  patientPicture={patientPicture}
                />
              )}
            {format === FORMATS_MESSAGE.PATIENT_DOCUMENT && sender === MESSAGE_SENDER.SYSTEM && (
              <DocumentSystemMessage data={message} onViewDocument={() => onViewDocument(documentId)} />
            )}
            {format === FORMATS_MESSAGE.VIDEO_JOIN && <SessionJoinVideo isPatient={isPatient} data={message} t={t} />}
            {format === FORMATS_MESSAGE.VIDEO_END && (
              <VideoEndEventMessage appointment={appointment} data={message} isPatient={isPatient} t={t} />
            )}
            {format === FORMATS_MESSAGE.LOADING && (
              <ImageMessageLoading isPatient={isPatient} data={message} onRetryClick={onRetryClick} t={t} />
            )}
            {format === FORMATS_MESSAGE.SESSION_END && <SessionEndEventMessage data={message} />}
            {format === FORMATS_MESSAGE.CHAT_START && (
              <ChatOnlyStart data={message} isPatient={isPatient} appointment={appointment} />
            )}
            {format === FORMATS_MESSAGE.CHAT_END && (
              <ChatOnlyEnd isPatient={isPatient} data={message} appointment={appointment} />
            )}
          </Fragment>
        );
      })}
      <DocumentPreviewTray file={file} show={showTray} onHide={onCloseTray} />
      <ExamReportViewer file={file} show={showExamReportViewer} onHide={onCloseExamReportViewer} />
    </>
  );
};

export default memo(MessageList, (prevProps, nextProps) => arePropsEqual(prevProps, nextProps, 'messages'));
