import { Info } from "phosphor-react";
import { Trans } from "react-i18next";
import useRTL from 'libs/hooks/useRtl';
import { Alert, Notes } from "./styles";

const TipsComponent = ({ message }) => {
  const { dir } = useRTL();

  return (
    <>
      {message !== "" && (
        <Alert $dir={dir}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Info color="#05548A" weight="fill" size={24} />
          </div>
          <p>
            {message}
          </p>
        </Alert>
      )}
      <Notes>
        <Trans i18nKey="If you feel your symptoms are worsening and cannot wait, we advise you not to wait for the virtual consultation and to visit the nearest healthcare facility for immediate attention.">
          If you feel your symptoms are worsening and cannot wait, we advise you <b>not to wait for the virtual consultation</b> and to visit the nearest healthcare facility for immediate attention.
        </Trans>
      </Notes>
    </>
  );
};

export default TipsComponent;
