export const RESPONSE_CODE = {
  UNAUTHIRIZED: 401,
  SUCCESS: 200,
  EXPIRED: 410,
  INVALID: 400,
  OUTSIDE_TIME_RANGE: 412,
  KONG_ERROR: 406
};

export const STATUS_TELEMEDICINE = {
  PRE_MEETING: 'pre-meeting',
  ACTIVE: 'active',
  WAITING: 'waiting'
};

export const RESPONSE_STATUS = {
  EXPIRED: 'expired',
  INVALID: 'invalid',
  OUTSIDE_TIME_RANGE: 'outside_time_range',
  KONG_INVALID_XDATE: 'Unauthorised'
};

export const APPOINTMENT_STATUS = {
  DONE: 'Done'
};

export const EVENT_MESSAGE_IFRAME = {
  PAYMENT_SUCCESS: 'paymentSuccess',
  TELEMEDICINE_STREAM_STATUS: 'telemedicine_stream_status',
  TOGGLE_BACK_BUTTON_PAGE: 'toggleBackButtonPage'
};

export const EVENT_PUBSUB = {
  CONSULTATION_END: 'CONSULTATION_END',
  PARTICIPANTS_STATUS: 'PARTICIPANTS_STATUS',
  CHAT: 'CHAT'
};

export const EVENT_TYPE_IFRAME = {
  SEND_TOKEN: 'SENT_TOKEN',
  DOCTOR_APP_ENDSESSION: 'endingSession',
  VIDEO_START: 'video_start',
  VIDEO_END: 'video_end',
  CHAT_START: 'chat_start',
  SWITCH_SCREEN_CHAT: 'switch_to_chat',
  LEFT_MEETING: 'redirect_to_other_page',
  PAGE_NAVIGATION: 'page-navigation',
  AUTH: 'AUTH',
  DOCUMENT_READY: 'DOCUMENT_READY'
};

export const CONSULTATION_STATUS = {
  YET_TO_JOIN: 'Yet to Join',
  NO_SHOW: 'No Show',
  IN_CONSULTATION: 'In Consultation',
  COMPLETED: 'Completed',
  LEFT_CONSULTATION: 'Left Consultation'
};

export const EVENTS_TYPE_SSE = {
  SESSION_END: 'session_end'
};

export const FORMATS_MESSAGE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO_JOIN: 'video_join',
  VIDEO_END: 'video_end',
  LOADING: 'loading',
  SESSION_END: 'session_end',
  CHAT_START: 'chat_start',
  CHAT_END: 'chat_end',
  PATIENT_DOCUMENT: 'patient_document',
  CHAT_SYSTEM: 'chat_system',
  EXAM_REPORT: 'remote_exam',
  QUESTIONNAIRE_DATA: 'questionnaire_data',
};

export const CHAT_SYSTEM_MESSAGE = {
  WAITING_ON_PATIENT: "While you're waiting, feel free to share any medical notes and documents with the doctor",
  COMPLETED_ON_PATIENT:
    'The consultation has been ended. You will receive an SMS and Email with a link to your medical report and prescription or you can access these from your documents.'
};

export const MESSAGE_SENDER = {
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  SYSTEM: 'system'
};

export const DEFAULT_MAX_UPLOAD_SIZE = 5;

export const ODD_SSE_EVENTS = {
  UPLOADED_DOCUMENT: 'uploaded_document',
  UPDATE_PARTICIPANT: 'update_participant',
  TOTAL_QUEUE: 'total_queue',
  WAITING_TIME: 'waiting_time',
  EXAM_REPORT: 'remote_exam'
};

export const ODD_SSE_CONFIG = {
  PATIENT: 'patient',
  DOCTOR: 'doctor'
};

export const CSAT_STEPS = {
  IDLE: 'idle',
  COMPLETE_SCREEN: 'complete-screen',
  QUESTION_SCREEN: 'question',
  THANK_YOU_SCREEN: 'thank-you'
};

export const CSAT_QUESTION_TYPE = {
  RANGE: 'range',
  MULTIPLE_CHOICES: 'multiple_choices'
};

export const EVENT_PAGE_NAVIGATION_ITEM = {
  APPOINTMENT_DETAIL: 'appointment-detail',
  ROUTE_BACK: 'router-back',
  SEARCH_PAGE: 'search-page'
};

export const EXAM_SSE_EVENTS = {
  PATIENT_EXAM: 'remote-exam'
};

export const POST_MESSAGE_ACTION = {
  // * Don't change this
  OPEN_EXTERNAL_BROWSER: 'openExternalBrowser'
};
