export const GetEventTelemedicineParticipant = appointmentNumber => {
  return new EventSource(
    `${
      process.env.REACT_APP_PIGEON_API_URL
    }/event/stream/appointment-telemedicine-participant-${appointmentNumber.toLowerCase()}`
  );
};

export const GetEventTelemedicine = appointmentNumber => {
  return new EventSource(
    `${
      process.env.REACT_APP_PIGEON_API_URL
    }/event/stream/appointment-telemedicine-${appointmentNumber.toLowerCase()}`
  );
};
