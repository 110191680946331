import { getQueryParams } from 'libs/url';

import TipsComponent from './tips';
import QueueComponent from './queue';
import useGetBlockQueue from './actions';
import { Container } from './styles';

const PreMeetingRapidCare = ({ queue = {}, doctor, setCheckinSuccess, setFetchingCheckin, checkinSuccess }) => {
  const query = getQueryParams(window.location);

  const { statePreMeeting, getQueueContent } = useGetBlockQueue({
    query,
    queue,
    setCheckinSuccess,
    setFetchingCheckin,
    checkinSuccess
  });

  return (
    <Container>
      <QueueComponent data={getQueueContent} statePreMeeting={statePreMeeting} doctor={doctor} />
      <TipsComponent message={statePreMeeting.tips} />
    </Container>
  );
};

export default PreMeetingRapidCare;
