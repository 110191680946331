import { useTranslation } from 'react-i18next';
import useRTL from 'libs/hooks/useRtl';
import { Container, Box, Estimation, Line, Wrapper } from './styles';
import { CONSULTATION_STATUS } from 'libs/constant';

const QueueComponent = ({ data, statePreMeeting, doctor }) => {
  const { t } = useTranslation();
  const { dir } = useRTL();
  const { participantStatus } = statePreMeeting;

  return (
    <>
      {participantStatus?.practitioner_status === CONSULTATION_STATUS.IN_CONSULTATION ? (
        <Wrapper>
          <Container $textAlign="center" $dir={dir}>
            <Estimation $textAlign="center">
              <b>{t("This consultation is ready to start")}</b>
              <p>{t('{{doctor}} has joined the consultation', { doctor: doctor?.title })}</p>
            </Estimation>
          </Container>
          <Line />
        </Wrapper>
      ) : (
        <Wrapper>
          {data?.totalQueue > 0 && (
            <>
              <Container $dir={dir}>
                <Estimation>
                  <b>{t("You’re next in the queue")}</b>
                  <p>{data?.estimation} {t("minutes")} {t("estimated waiting")}</p>
                </Estimation>
                <Box>
                  <b>{data?.totalQueue}</b>
                  <p>{t("in queue")}</p>
                </Box>
              </Container>
              <Line />
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default QueueComponent;
