import { Gear } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import useRTL from 'libs/hooks/useRtl';
import useActions from './actions';
import { Bold, List, Bubble, Line, Info, BubbleContent } from './styles';

const QuestionnaireNotes = ({
  data
}) => {
  const { t } = useTranslation();
  const { dir } = useRTL();
  const { notes } = useActions({
    data,
  });

  return (
    <div className="chat-message">
      <div className="chat-message__profile-picture">
        <div className="thumbnail">
          <div className="thumbnail-default">
            <Gear size={18} />
          </div>
        </div>
      </div>
      <Bubble $dir={dir}>
        <BubbleContent>
          <p>{t("Notes from Symptoms Questionnaire")}</p>
          <List>
            {notes.map?.((note) => (
              <li key={note.question}>
                <Line>
                  {note.question}&nbsp;
                  <Bold className='text-bold'>
                    {note.answer}
                  </Bold>
                </Line>
              </li>
            ))}
          </List>
        </BubbleContent>

        <Info $dir={dir}>
          {t("System Message")}
        </Info>
      </Bubble>
    </div>
  );
};

export default QuestionnaireNotes;
