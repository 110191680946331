// * based on https://react-bootstrap.github.io/components/spinners/

import Spin from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';

import './styles.scss';

const Spinner = ({ animation = 'border', as = 'div', size = '', variant = 'primary' }) => (
  <div className="spinner">
    <Spin animation={animation} as={as} size={size} variant={variant} />
  </div>
);

Spinner.propTypes = {
  animation: PropTypes.oneOf(['border', 'grow']),
  as: PropTypes.string,
  size: PropTypes.oneOf(['sm', '']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'])
};

export default Spinner;
