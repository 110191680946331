import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const Alert = styled.div`
  background: var(--color-lightBlue);
  padding: 12px;
  border-radius: 4px;
  column-gap: 12px;
  display: flex;
  align-items: center;
  color: ${Colors.text.RhinoGrey};

  > p {
    font-size: 14px
  }

  ${({ $dir }) => `
    direction: ${$dir};
  `}

  margin-top: 16px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

export const Notes = styled.p`
  font-size: 14px;
  color: ${Colors.text.RhinoGrey};
  text-align: center;
  margin: 16px 0 24px;

  @media (min-width: 768px) {
    font-size: 16px;
    margin: 32px 0;
  }
`;
