import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 16px;

  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ $dir }) => `
      direction: ${$dir};
    `}

    ${({ $textAlign }) => `
      text-align: ${$textAlign};
    `}
`;

export const Estimation = styled.div`
    color: #363D45;

    > b {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    > p {
        font-size: 14px;
        line-height: 20px;
    }

    width: 100%;
`;

export const Box = styled.div`
    width: 60px;
    height: 55px;
    border-radius: 4px;
    background-color: #315679;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 6px;

    > b {
        font-size: 18px;
        line-height: 28px;
    }

    > p {
        font-size: 12px;
        line-height: 16px;
    }
`;

export const Line = styled.div`
    border-bottom: 1px solid #E8E9EA;
    margin-top: 16px;
    margin-bottom: 16px;

    @media (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
    }
`;
