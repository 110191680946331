import React, { useRef, useEffect, Fragment, useState, useMemo, useCallback, memo } from 'react';
import { useParticipant, usePubSub, useMeeting, useMediaDevice } from '@videosdk.live/react-sdk';
import Colors from 'okadoc-component-ui/lib/Colors';

// Libs
import { isMobile, mobileBreakPoint } from 'libs/okaBrowserCheck';
import useChat from 'libs/hooks/useChat';
import { CONSULTATION_STATUS, EVENT_PUBSUB } from 'libs/constant';
import { getFullDatetime } from 'libs/date';
import { getQualityScore } from 'libs/network';
import { useGlobalConfigStore } from 'zustandStore';
import useMediaStream from 'libs/hooks/useMediaStream';
// import { useTranslation } from 'react-i18next';

// Components
import ToolBox from 'components/Toolbox';
import NetworkIndicatorTable from './network-indicator-table';
import cn from 'classnames';
import { Microphone, MicrophoneSlash, WifiHigh, WifiMedium, WifiLow, PictureInPicture } from 'phosphor-react';
import { Indicator } from './indicator-wrapper';

import DoctorPreview from '../../../assets/images/avatar-doctor.png';
import { useVideoSDK } from 'libs/hooks/useVideoSDK';
import VideoPlayer from 'components/video-player';

import useTracking from 'libs/hooks/useTracking';
import { VirtualBackgroundProcessor } from '@videosdk.live/videosdk-media-processor-web';
import { getVirtualBackgroundUrl, isUseVirtualBackground } from 'libs/virtual-background';
import { useMeetingAppContext } from 'MeetingAppContextDef';

const chunk = arr => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, 3));
  return newArr;
};

const getIndicatorColor = score => {
  return score > 7 ? '#04850A' : score > 4 ? '#CA9E00' : '#C20000';
};

const IndicatorComponent = props => {
  const participantId = props?.participantId ?? '';
  const { webcamStream, micStream, micOn, isLocal, getVideoStats, getAudioStats } = useParticipant(participantId);
  const statsIntervalIdRef = useRef();
  const networkBarEnabled = useGlobalConfigStore(state => state.networkBarEnabled);

  const [score, setScore] = useState(0);
  const [audioStats, setAudioStats] = useState({});
  const [videoStats, setVideoStats] = useState({});
  const [networkTable, setNetworkTable] = useState(false);

  const NetworkIndicator = useMemo(() => {
    if (score > 7) return WifiHigh;
    else if (score > 4) return WifiMedium;

    return WifiLow;
  }, [score]);

  const MuteIndicator = useMemo(() => {
    if (micOn) {
      return Microphone;
    }
    return MicrophoneSlash;
  }, [micOn]);

  const networkIndicatorColor = useMemo(() => getIndicatorColor(score), [score]);

  const updateStats = async () => {
    let stats = [];

    if (webcamStream) {
      stats = await getVideoStats();
    } else if (micStream) {
      stats = await getAudioStats();
    }

    const score = stats ? (stats.length > 0 ? getQualityScore(stats[0]) : 100) : 100;

    setScore(score);

    if (!isLocal) {
      let audioStats = [];
      let videoStats = [];

      if (webcamStream || micStream) {
        videoStats = await getVideoStats();
        audioStats = await getAudioStats();
      }

      setAudioStats(audioStats);
      setVideoStats(videoStats);
    }
  };

  useEffect(() => {
    if (networkBarEnabled) {
      if (webcamStream || micStream) {
        updateStats();

        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
        }

        statsIntervalIdRef.current = setInterval(updateStats, 10000);
      } else {
        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
          statsIntervalIdRef.current = null;
        }
      }

      return () => {
        if (statsIntervalIdRef.current) clearInterval(statsIntervalIdRef.current);
      };
    }
  }, [webcamStream, networkBarEnabled, micStream]);

  const handleOpenNetworkDetail = e => {
    e.stopPropagation();
    !isLocal && setNetworkTable(current => !current);
  };

  return (
    <div className={`${!isLocal ? 'network-indicator-wrapper' : ''}`}>
      <div
        className={`${!isLocal ? 'network-indicator' : 'network-indicator-local'}`}
        style={{
          top: isLocal ? (props?.shouldShowSmallContainer ? 4 : 10) : '',
          left: isLocal ? 10 : ''
        }}
      >
        <Indicator style={{ marginRight: !isLocal ? 8 : '' }} small={props?.shouldShowSmallContainer}>
          <MuteIndicator color={Colors.text.WhiteText} size={props?.shouldShowSmallContainer ? 16 : 24} />
        </Indicator>
        <Indicator
          onClick={handleOpenNetworkDetail}
          backgroundColor={networkIndicatorColor}
          style={{ marginRight: !isLocal ? 8 : '' }}
          small={props?.shouldShowSmallContainer}
        >
          <NetworkIndicator color={Colors.text.WhiteText} size={props?.shouldShowSmallContainer ? 16 : 24} />
        </Indicator>
        {!isLocal && (
          <Indicator onClick={props.togglePiP}>
            <PictureInPicture size={24} weight="fill" color={Colors.text.WhiteText} />
          </Indicator>
        )}
      </div>
      {!isLocal && networkTable && (
        <NetworkIndicatorTable isLocal={isLocal} audioStats={audioStats} videoStats={videoStats} score={score} />
      )}
    </div>
  );
};

const ParticipantViewComponent = ({ participantId, toggleView, hasMoreParticipant }) => {
  const {
    value: { doctor, patient, isDoctor }
  } = useChat();
  // const { t } = useTranslation();
  const { publish } = usePubSub(EVENT_PUBSUB.PARTICIPANTS_STATUS, {});
  const meeting = useVideoSDK();
  // const participantStatus = useGlobalConfigStore(state => state.participantStatus);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, setQuality } = useParticipant(participantId);
  const { selectedSpeaker, setRemoteStreamAvailable } = useMeetingAppContext();

  const webcamRef = useRef(null);
  const micRef = useRef(null);
  const [portrait, setPortrait] = useState(false);

  const getVideoElement = () => {
    const videoElement = webcamRef.current?.getInternalPlayer();
    const isPictureInPictureMode = !!document?.pictureInPictureElement;

    return {
      videoElement,
      isPictureInPictureMode
    };
  };

  const checkAndUpdatePortrait = () => {
    if (webcamStream) {
      const { height, width } = webcamStream.track.getSettings();
      if (height > width && !portrait) {
        setPortrait(true);
      } else if (height < width && portrait) {
        setPortrait(false);
      }
    } else {
      if (portrait) setPortrait(false);
    }
  };

  useEffect(() => {
    if (!isLocal) {
      setRemoteStreamAvailable({ audio: micStream ? true : false, video: webcamStream ? true : false });
    }
  }, [micStream, webcamStream]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);
        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch(error => console.error('micRef.current.play() failed', error));
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn, micRef]);

  const webcamMediaStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if (micRef.current) {
      try {
        if (!isFirefox && !isMobile()) {
          micRef.current.setSinkId(selectedSpeaker.id);
        }
      } catch (err) {
        console.log('Setting speaker device failed', err);
      }
    }
  }, [selectedSpeaker]);

  useEffect(() => {
    setQuality('high');
  }, [webcamStream]);

  useEffect(() => {
    if (meeting.isJoined) {
      const messageToSent = {
        message: {
          status: CONSULTATION_STATUS.IN_CONSULTATION,
          isDoctor: isDoctor
        },
        type: 'message'
      };
      publish(JSON.stringify(messageToSent), { persist: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.isJoined]);

  const shouldShowSmallContainer = useMemo(() => {
    if (!hasMoreParticipant && !toggleView) return false;
    if (hasMoreParticipant) return !toggleView;
  }, [hasMoreParticipant, toggleView]);

  const mainScreenClass = cn('video-container', {
    'video-container-small': shouldShowSmallContainer
  });

  const playerWrapper = cn('player-wrapper', {
    'video-container-small-wrapper': shouldShowSmallContainer
  });

  const participantContainerClass = cn('video-container', {
    'video-mobile-resolution': true
  });

  const usernamePreviewVideo = useMemo(() => {
    const currentParticipant = isDoctor ? doctor : patient;
    return isDoctor
      ? currentParticipant?.title
      : [currentParticipant?.first_name, currentParticipant?.last_name].join(' ');
  }, [isDoctor, doctor, patient]);

  // const remoteUsernamePreviewVideo = useMemo(() => {
  //   const remoteParticipant = isDoctor ? patient : doctor;

  //   return isDoctor
  //     ? [remoteParticipant?.first_name, remoteParticipant?.last_name].join(' ')
  //     : remoteParticipant?.title;
  // }, [doctor, isDoctor, patient]);

  // const status = useMemo(
  //   () => (isDoctor ? participantStatus?.patient : participantStatus?.practitioner),
  //   [isDoctor, participantStatus?.patient, participantStatus?.practitioner]
  // );

  const firstCharacterName = usernamePreviewVideo?.split('')?.[0];

  const heightActiveScreen = shouldShowSmallContainer ? 'auto' : '100%'; // set height local video player based on toggleView and participants join

  const profilePicture = useMemo(() => {
    const remoteUser = !isDoctor ? doctor : patient;
    return isDoctor ? remoteUser?.photo : remoteUser?.profilePicture;
  }, [doctor, isDoctor, patient]);

  const togglePiP = async () => {
    try {
      const { videoElement, isPictureInPictureMode } = getVideoElement();
      if (typeof document?.exitPictureInPicture === 'function' && isPictureInPictureMode) {
        await document?.exitPictureInPicture();

        return;
      }

      if (videoElement.requestPictureInPicture) {
        if (!isPictureInPictureMode) {
          await videoElement.requestPictureInPicture();
        }
      } else {
        console.log('PiP not supported on this video Element');
      }
    } catch (err) {
      console.log('Failed to enter PiP mode', err);
    }
  };

  return (
    <>
      <div id="videoconference_page" className="video-wrapper">
        {<audio autoPlay ref={micRef} muted={isLocal} />}

        <div className={`${isLocal ? mainScreenClass : participantContainerClass}`}>
          <div
            className={`${isLocal ? playerWrapper : participantContainerClass}`}
            style={{ position: isLocal ? 'relative' : '' }}
          >
            <IndicatorComponent
              participantId={participantId}
              shouldShowSmallContainer={isLocal ? shouldShowSmallContainer : false}
              togglePiP={togglePiP}
            />

            {!webcamOn && (
              <div className="video-preview-wrapper">
                {isLocal && shouldShowSmallContainer ? (
                  <div className="video-preview">
                    <div className="video-preview--avatar">
                      <span>{firstCharacterName}</span>
                    </div>
                    <div className="video-preview--username">{usernamePreviewVideo}</div>
                  </div>
                ) : (
                  <div className={`${isLocal ? 'video-preview-main' : 'main-video-cam--preview'}`}>
                    <img
                      alt="participant-preview"
                      src={profilePicture ?? DoctorPreview}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DoctorPreview;
                      }}
                      height={'auto'}
                      width={isMobile() ? 120 : 180}
                    />
                  </div>
                )}
              </div>
            )}
            <VideoPlayer
              ref={webcamRef}
              wrapper={({ children }) => children}
              url={webcamMediaStream}
              width="100%"
              height={isLocal ? heightActiveScreen : '100%'}
              onError={err => {
                console.log(err, 'participant video error');
              }}
              onProgress={!isLocal ? checkAndUpdatePortrait : () => {}}
              className={!isLocal ? 'main-video-cam' : ''}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const ParticipantView = memo(ParticipantViewComponent);

const ParticipantsView = ({
  participantType,
  setShowFullChat,
  setShowVideo,
  setShowChat,
  setShowInfo,
  setIsUnread
}) => {
  const tracking = useTracking({
    from: 'ParticipantsView'
  });

  const { localMicOn, localWebcamOn } = useMeeting();
  const { setToggledStates } = useMeetingAppContext();
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const {
    value: { doctor, patient, isDoctor, appointment },
    action
  } = useChat();

  const mMeetingRef = useRef();
  const didDeviceChange = useRef();
  const [toggleView, setToggleView] = useState();
  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);
  const [toggleShowSpeakerDeviceList, setToggleShowSpeakerDeviceList] = useState(false);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const { selectedWebcam, selectedMic, initialMicOn, setInitialMicOn } = useMeetingAppContext();
  const { getVideoTrack, getAudioTrack } = useMediaStream();
  const videoProcessor = useRef();
  const {} = useMediaDevice({
    onDeviceChanged
  });

  function onDeviceChanged() {
    didDeviceChange.current = true;
  }

  useEffect(() => {
    if (didDeviceChange.current) {
      handleChangeMic(selectedMic.id);
      handleChangeCamera(selectedWebcam.id);
      didDeviceChange.current = false;
    }
  }, [selectedMic, selectedWebcam]);

  const onMeetingLeft = useCallback(() => {
    tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
      micOn: localMicOn,
      webcamOn: localWebcamOn,
      action: 'meeting_left'
    });
  }, [tracking, isDoctor]);

  useEffect(() => {
    videoProcessor.current = new VirtualBackgroundProcessor();
  }, []);

  const meeting = useVideoSDK({
    onMeetingLeft
  });

  const switchToChat = () => {
    const isMobile = mobileBreakPoint();
    setShowFullChat(true);
    setShowVideo(false);
    setShowChat(false);
    setShowInfo(!isMobile);
    setToggledStates({ webcamOn: localWebcamOn, micOn: localMicOn });
    localMicOn && meeting.toggleMic();
    localWebcamOn && meeting.toggleWebcam();
    setIsUnread(false);
  };

  const processVideoStream = useCallback(
    async stream => {
      if (isUseVirtualBackground(isDoctor)) {
        if (!videoProcessor.current.ready) {
          await videoProcessor.current.init();
        }
        const config = {
          type: 'image',
          imageUrl: getVirtualBackgroundUrl()
        };
        const processedStream = await videoProcessor.current.start(stream, config);
        return processedStream;
      } else {
        return stream;
      }
    },
    [isDoctor]
  );

  const handleLeave = useCallback(
    () => {
      const remoteUser = !isDoctor ? doctor : patient;
      const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');

      const eventEndCall = {
        ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
        format: 'video_end',
        message: 'video_end',
        text: 'video_end',
        time: getFullDatetime,
        sender: !isDoctor ? 'patient' : 'doctor'
      };
      const eventPayload = JSON.stringify(eventEndCall);

      action.saveChat({
        appointmentNumber: appointment.appointmentNumber,
        message: eventEndCall
      });
      publishChat(eventPayload, { persist: false });

      toggleState({
        isPatientJoinTheCall: false
      });

      switchToChat();

      tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
        webcamOn: localWebcamOn,
        micOn: localMicOn,
        action: 'leave_room_button'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointment.appointmentNumber, doctor, isDoctor, switchToChat, localMicOn, patient, localWebcamOn]
  );

  const doctorCameraConfig = {
    optimizationMode: 'motion',
    cameraId: selectedWebcam.id,
    encoderConfig: 'h540p_w720p',
    multiStream: true
  };

  const patientCameraConfig = {
    optimizationMode: 'motion',
    cameraId: selectedWebcam.id,
    encoderConfig: 'h720p_w1280p',
    multiStream: false
  };

  const handleClickOutside = () => {
    setToggleShowDeviceList(false);
    setToggleShowMicDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };

  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
    setToggleShowMicDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowMicDeviceList(show => !show);
    setToggleShowDeviceList(false);
    setToggleShowSpeakerDeviceList(false);
  };

  const handleShowSpeakerDeviceList = e => {
    e.stopPropagation();
    setToggleShowSpeakerDeviceList(show => !show);
    setToggleShowDeviceList(false);
    setToggleShowMicDeviceList(false);
  };

  const getVideoElement = () => {
    const isPictureInPictureMode = !!document?.pictureInPictureElement;

    return {
      isPictureInPictureMode
    };
  };

  const handleChangeCamera = async cameraId => {
    if (cameraId && cameraId != meeting?.selectedCameraDevice.deviceId) {
      const { isPictureInPictureMode } = getVideoElement();
      // Create the track only for picture in picture mode
      let track;
      if (isPictureInPictureMode) {
        const config = isDoctor ? doctorCameraConfig : patientCameraConfig;
        const updatedConfig = {
          ...config,
          cameraId: cameraId
        };
        track = await getVideoTrack(updatedConfig);
        const processedStreamID = await processVideoStream(track);
        meeting?.changeWebcam(processedStreamID);
      } else {
        meeting?.changeWebcam(cameraId);
      }

      tracking.trackEvent('switch_camera', isDoctor ? 'doctor' : 'patient', {
        micOn: localMicOn,
        webcamOn: localWebcamOn,
        isPictureInPictureMode,
        webcamConfig: isPictureInPictureMode && track,
        action: 'main_participant_view_toolbox_change_device'
      });

      tracking.trackEvent('enable_video', isDoctor ? 'doctor' : 'patient', {
        micOn: localMicOn,
        webcamOn: localWebcamOn,
        action: 'main_participant_view_toolbox_change_device'
      });

      setToggleShowDeviceList(false);
    }
  };

  useEffect(() => {
    if (initialMicOn) {
      handleLocalToggleMic();
    }
  }, []);

  const handleChangeMic = micId => {
    if (micId) {
      meeting?.changeMic(micId);

      tracking.trackEvent('switch_mic', isDoctor ? 'doctor' : 'patient', {
        micOn: localMicOn,
        webcamOn: localWebcamOn,
        action: 'main_participant_view_toolbox_change_device'
      });
      tracking.trackEvent('enable_audio', isDoctor ? 'doctor' : 'patient', {
        micOn: localMicOn,
        webcamOn: localWebcamOn,
        action: 'main_participant_view_toolbox_change_device'
      });
      setToggleShowMicDeviceList(false);
    }
  };

  const handleChangeSpeaker = speakerId => {
    if (speakerId) {
      setToggleShowSpeakerDeviceList(false);
    }
  };

  const handleLocalToggleMic = async () => {
    if (initialMicOn || !localMicOn) {
      const stream = await getAudioTrack({
        micId: selectedMic.id
      });
      meeting?.toggleMic(stream);
      setInitialMicOn(false);
    } else {
      meeting?.toggleMic();
    }

    tracking.trackEvent(!localMicOn ? 'enable_audio' : 'disable_audio', isDoctor ? 'doctor' : 'patient', {
      micOn: localMicOn,
      webcamOn: localWebcamOn,
      action: 'main_participant_view_toolbox'
    });
  };

  const handleLocalToggleWebcam = async () => {
    const { isPictureInPictureMode } = getVideoElement();

    // For Picture in Picture Mode toggle the webcam on with processed stream
    if (isPictureInPictureMode && !localWebcamOn) {
      const track = await getVideoTrack(isDoctor ? doctorCameraConfig : patientCameraConfig);
      const processedStream = await processVideoStream(track);
      meeting?.toggleWebcam(processedStream);
    } else {
      if (localWebcamOn && isUseVirtualBackground(isDoctor)) {
        videoProcessor.current.stop();
      }
      meeting?.toggleWebcam();
    }

    tracking.trackEvent(!localWebcamOn ? 'enable_video' : 'disable_video', isDoctor ? 'doctor' : 'patient', {
      micOn: localMicOn,
      webcamOn: localWebcamOn,
      action: 'main_participant_view_toolbox'
    });
  };

  const setLayout = () => {
    let layoutView = 'vertical-view';
    if (meeting.participants.size === 1) {
      return layoutView;
    }

    if (toggleView) {
      layoutView = 'tile-view';
    }

    return layoutView;
  };

  useEffect(() => {
    mMeetingRef.current = meeting;
  }, [meeting]);

  const participantMeeting = useMemo(() => chunk([...meeting.participants.keys()]), [meeting?.participants]);

  return (
    <>
      {participantMeeting.map(k => (
        <Fragment key={k}>
          <div className={`video-layout ${setLayout()} ${meeting.participants.size === 1 ? 'single' : ''}`}>
            {k.map(l => (
              <ParticipantView
                key={l}
                hasMoreParticipant={+meeting.participants.size > 1}
                participantId={l}
                participantType={participantType}
                toggleView={toggleView}
                setToggleView={setToggleView}
              />
            ))}
          </div>
        </Fragment>
      ))}

      <ToolBox
        className="toolbox toolbox-wrapper toolbox-overlay"
        micOn={localMicOn}
        webcamOn={localWebcamOn}
        toggleView={toggleView}
        handleToggleMic={handleLocalToggleMic}
        handleToggleWebcam={handleLocalToggleWebcam}
        handleLeave={handleLeave}
        handleView={setToggleView}
        handleChangeCamera={handleChangeCamera}
        handleChangeMic={handleChangeMic}
        handleChangeSpeaker={handleChangeSpeaker}
        handleShowDeviceList={handleShowDeviceList}
        handleShowMicDeviceList={handleShowMicDeviceList}
        handleShowSpeakerDeviceList={handleShowSpeakerDeviceList}
        showDeviceList={toggleShowDeviceList}
        showMicList={toggleShowMicDeviceList}
        showSpeakerList={toggleShowSpeakerDeviceList}
        handleClickOutside={handleClickOutside}
        hasMultipleSources
        showSwitchView
        showEndCall
      />
    </>
  );
};

export default memo(ParticipantsView);
