import { useEffect, useMemo, useState, useRef } from 'react';
import LocaleAPI from 'libs/api/locale';
import { GetEventTelemedicine as getEventTelemedicine } from 'libs/services/eventsources';
import useChat from 'libs/hooks/useChat';
import { ODD_SSE_EVENTS } from 'libs/constant';
import AppointmentAPI from 'libs/api/appointment';
import moment from 'moment';
import useToast from 'libs/hooks/useToast';
import { useTranslation } from 'react-i18next';

const useGetBlockQueue = ({ query, queue, setCheckinSuccess, setFetchingCheckin, checkinSuccess }) => {
  const { t } = useTranslation();
  const { value } = useChat();

  const checkinSuccessRef = useRef(checkinSuccess);
  const appointmentNumber = value?.appointment?.appointmentNumber;
  const appointmentStart = value?.appointment?.startTime;
  const fgOddAppointment = value?.appointment?.isFgOddAppointment;
  const { show } = useToast();

  const [statePreMeeting, setStatePreMeeting] = useState({
    tips: '',
    showEstimationBlock: false,
    queueData: queue || {},
    participantStatus: {}
  });

  const getBlockContent = useMemo(
    () => async () => {
      const [, resp] = await LocaleAPI.getBulk(
        {
          keys: ['whitelabel-ehs-gov-erapidcare-symptoms-ae'].join(',')
        },
        query.lang
      );

      const blockData = JSON.parse(resp?.data?.data[0].value || '[]');

      const symptom = blockData?.symptoms.find?.(block => block.action === query?.symptom);

      setStatePreMeeting(prevState => ({
        ...prevState,
        tips: symptom?.tips || ''
      }));
    },
    [query?.symptom, query?.lang]
  );

  const getQueueContent = useMemo(() => {
    const { queueData } = statePreMeeting;
    const estimation = `${queueData?.waiting_estimation_from}-${queueData?.waiting_estimation_to}`;
    const totalQueue = queueData?.total_current_queue;

    return {
      estimation,
      totalQueue
    };
  }, [statePreMeeting.queueData]);

  useEffect(() => {
    getBlockContent();
  }, [getBlockContent]);

  useEffect(() => {
    const { queueData } = statePreMeeting;

    setStatePreMeeting(prevState => ({
      ...prevState,
      showEstimationBlock: queueData?.total_current_queue > 0
    }));
  }, [statePreMeeting.queueData]);

  const callCheckinAPI = async () => {
    setFetchingCheckin(true);

    if (!checkinSuccess) {
      const [, response] = await AppointmentAPI.selfCheckIn(appointmentNumber);

      if (response?.status === 200) {
        setCheckinSuccess(true);
      } else {
        show({
          type: 'danger',
          message: t('Unable to check in')
        });
        setCheckinSuccess(false);
      }
    }

    setFetchingCheckin(false);
  };

  const callApiUnder5Minute = () => {
    const startTime = moment(appointmentStart);
    const diffTime = startTime.diff(moment(), 'minutes', true);

    if (diffTime <= 5) {
      callCheckinAPI();
    } else {
      setCheckinSuccess(false);
    }
  };

  useEffect(() => {
    checkinSuccessRef.current = checkinSuccess;
  }, [checkinSuccess]);

  useEffect(() => {
    const { queueData } = statePreMeeting;
    const MINUTE_MS = 60000;

    const callSelfCheckin = async () => {
      if (!fgOddAppointment) {
        callCheckinAPI();
      } else {
        if (queueData?.total_current_queue === 0) {
          callCheckinAPI();
        } else {
          const startTime = moment(appointmentStart);
          const diffTime = startTime.diff(moment(), 'minutes', true);

          if (diffTime <= 5) {
            callCheckinAPI();
          } else {
            setCheckinSuccess(false);

            const intervalCallApi = setInterval(() => {
              if (!checkinSuccessRef.current) {
                callApiUnder5Minute();
              } else {
                clearInterval(intervalCallApi);
              }
            }, MINUTE_MS);

            return () => clearInterval(intervalCallApi);
          }
        }
      }
    };

    callSelfCheckin();
  }, [statePreMeeting.queueData]);

  useEffect(() => {
    if (appointmentNumber) {
      const eventSource = getEventTelemedicine(appointmentNumber);

      const getRealtimeDataMessage = e => {
        try {
          const message = JSON.parse(e?.data || '{}');
          if (message) {
            if (message.type === ODD_SSE_EVENTS.TOTAL_QUEUE) {
              setStatePreMeeting(prevState => ({
                ...prevState,
                queueData: message.data
              }));
            }

            if (message.type === ODD_SSE_EVENTS.UPDATE_PARTICIPANT) {
              setStatePreMeeting(prevState => ({
                ...prevState,
                participantStatus: message.data
              }));
            }
          }
        } catch (err) {
          console.error(err);
        }
      };

      eventSource.onmessage = getRealtimeDataMessage;
      eventSource.onerror = () => {
        eventSource.close();
      };
      return () => {
        eventSource.close();
      };
    }
  }, [appointmentNumber]);

  return {
    statePreMeeting,
    getQueueContent
  };
};

export default useGetBlockQueue;
