/* eslint-disable new-cap */
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import arLocale from 'dayjs/locale/ar-sa';
import Png from 'components/Png';
import Collapsible from 'components/Collapsible';
import useChat from 'libs/hooks/useChat';
import DateHelper from 'libs/datetimegreg';
import PropTypes from 'prop-types';
import { CONSULTATION_STATUS } from 'libs/constant';
import { sendMessage } from 'libs/message';
import CopyableMeetingInfo from './copyable-meeting-info';

const RenderInfo = ({ title = 'Dr. Azeri Test 02 (Patientweb Only)', value = 'Yet to Join' }) => {
  return (
    <li className="doctor-detail__item mt-15">
      <p className="doctor-detail__subtitle">{title}</p>
      <p className="doctor-detail__subtitle--value">{value}</p>
    </li>
  );
};

const StatusBadge = ({ status }) => {
  let badge = '';
  const { t } = useTranslation();

  if (status === CONSULTATION_STATUS.YET_TO_JOIN) {
    badge = 'warning';
  } else if (status === CONSULTATION_STATUS.NO_SHOW) {
    badge = 'danger';
  } else if (status === CONSULTATION_STATUS.IN_CONSULTATION) {
    badge = 'info';
  } else if (status === CONSULTATION_STATUS.COMPLETED) {
    badge = 'success';
  } else {
    badge = 'grey';
  }
  return <span className={`badge badge--${badge} mt-10`}>{t('{{status}}', { status: t(`${status}`) })}</span>;
};

const InfoView = ({ isTestingMode = false, status, isXToken }) => {
  const { value } = useChat();
  const { t } = useTranslation();
  const isDoctorView = value?.isDoctor;
  const doctor = value?.doctor;
  const patient = value?.patient;
  const appointment = value?.appointment;
  const remoteUser = !isDoctorView ? doctor : patient;
  const profilePicture = isDoctorView ? remoteUser?.photo : remoteUser?.profilePicture;
  const userName = !isDoctorView ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');
  const locale = value?.lang === 'ar' ? 'ar-sa' : value?.lang;
  // Set Locale with unique case for Arabic translation
  DateHelper.setLocale(
    value?.lang === 'ar'
      ? {
          ...arLocale,
          name: locale,
          meridiem: (hour, minute) => {
            if (hour >= 12 && minute >= 0) {
              return 'م';
            }

            return 'ص';
          }
        }
      : locale
  );
  const day = DateHelper.format(appointment?.startTime, 'dddd');
  const startTime = DateHelper.format(appointment?.startTime, 'hh:mm A');
  const endTime = DateHelper.format(appointment?.endTime, 'hh:mm A');
  const monthName = DateHelper.format(appointment?.startTime, 'MMMM');
  const dateOfMonth = DateHelper.format(appointment?.startTime, 'D');
  const year = DateHelper.format(appointment?.startTime, 'YYYY');
  const appointmentDate = `${t(day)}, ${dateOfMonth} ${t(monthName)}, ${year}${' '}`;
  const scheduledTime = `${startTime} - ${endTime}`;
  const meetingId = value?.telemedicine?.meetingId;

  const onManageAppointment = () => {
    if (typeof window === 'undefined') return null;

    const facilityId = appointment?.facilityId || '';
    const appointmentNumber = appointment?.appointmentNumber || '';
    const doctorId = doctor?.id || '';

    const manageAppointmentLink = `${process.env.REACT_APP_MANAGE_APPOINTMENT_DOCTOR}/doctors/${doctorId}/calendar?facilityId=${facilityId}&appointment-number=${appointmentNumber}`;

    sendMessage({
      message: {
        type: 'ManageAppointmentLink',
        data: manageAppointmentLink
      }
    });
  };

  return (
    <>
      <div className="sidebar__info p-15">
        <div className="d-flex justify-content-center">
          <div className="thumbnail thumbnail--md">
            {profilePicture && (
              <Png external name={profilePicture} alt="doctor profile" className="thumbnail__image" width="60px" />
            )}
            {!profilePicture && (
              <Png name="doctor-profile-default" alt="doctor profile" className="thumbnail__image" width="10px" />
            )}
          </div>
        </div>
        <div className="text-center">
          <h6 className="sidebar__info-title text-truncate mt-10">{isTestingMode ? 'John Doe' : userName}</h6>
          {status && <StatusBadge status={status} />}
          <CopyableMeetingInfo appointment={appointment} meetingId={meetingId} />
        </div>
      </div>
      <div className="sidebar__menu">
        <div className="list-group">
          <Collapsible title={isDoctorView ? t('Patient Details') : t('Doctor Details')}>
            <ul className="doctor-detail">
              {isDoctorView ? (
                <>
                  <RenderInfo
                    title={t('Gender')}
                    value={patient.gender ? t(patient.gender) : patient.fg_gender === 1 ? t('Male') : t('Female')}
                  />
                  <RenderInfo title={t('Date of Birth')} value={moment(patient?.birthday).format('MMM DD YYYY')} />
                  <RenderInfo title={t('Phone Number')} value={patient?.phone} />
                  <RenderInfo title={t('Emirates ID Number')} value={patient?.identity} />
                </>
              ) : (
                <>
                  <RenderInfo title={t('Title')} value={t('{{doctorBio}}', { doctorBio: t(`${doctor?.bio}`) })} />
                  <RenderInfo title={t('Hospital')} value={doctor?.location?.name || ''} />
                  <RenderInfo title={t('Spoken Languages')} value={(doctor?.spoken_language || []).join(', ')} />
                </>
              )}
            </ul>
          </Collapsible>
          <Collapsible title={t('Consultation')}>
            <ul className="doctor-detail" dir={value?.lang === 'ar' ? 'rtl' : 'ltr'}>
              <RenderInfo title={t('Date')} value={appointmentDate} />
              <RenderInfo title={t('Time')} value={scheduledTime} />
              <RenderInfo title={t('Type')} value={t(appointment?.serviceCategoryName) || ''} />
            </ul>
          </Collapsible>
          {isDoctorView && !isXToken && (
            <div className="list-group">
              <div className="list-group__item" style={{ marginTop: 'auto', border: 'none' }}>
                <button onClick={onManageAppointment} className="text-center text-bold btn-manage">
                  {t('Manage Appointment')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

InfoView.propTypes = {
  isTestingMode: PropTypes.bool
};

export default InfoView;
