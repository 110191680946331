import { useEffect } from 'react';
import { GetEventTelemedicineParticipant as getEventTelemedicineParticipant } from 'libs/services/eventsources';
import useChat from 'libs/hooks/useChat';

const useServerSideEvent = props => {
  const { value } = useChat();
  const appointmentNumber = value?.appointment?.appointmentNumber;

  useEffect(() => {
    if (appointmentNumber) {
      const eventSource = getEventTelemedicineParticipant(appointmentNumber);

      const getRealtimeDataMessage = e => {
        try {
          const message = JSON.parse(e?.data || '{}');
          const event = e?.event || '';
          if (message) {
            props?.onMessage(event, message);
          }
        } catch (err) {
          props?.onError(err);
        }
      };

      eventSource.onmessage = getRealtimeDataMessage;
      eventSource.onerror = () => {
        eventSource.close();
      };
      return () => {
        eventSource.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentNumber, ...(props?.deps ?? [])]);
};

export default useServerSideEvent;
