import { createCameraVideoTrack, createMicrophoneAudioTrack } from '@videosdk.live/react-sdk';

const useMediaStream = () => {
  const getVideoTrack = async ({ optimizationMode, cameraId, encoderConfig, multiStream }) => {
    try {
      const track = await createCameraVideoTrack({
        optimizationMode: optimizationMode,
        cameraId: cameraId,
        encoderConfig: encoderConfig,
        multiStream: multiStream
      });

      return track;
    } catch (e) {
      return null;
    }
  };

  const getAudioTrack = async ({ micId }) => {
    try {
      const track = await createMicrophoneAudioTrack({
        microphoneId: micId
      });
      return track;
    } catch (error) {
      return null;
    }
  };

  return { getVideoTrack, getAudioTrack };
};

export default useMediaStream;
