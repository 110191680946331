import styled from 'styled-components';
import Colors from 'okadoc-component-ui/lib/Colors';

export const AppointmentMeetingInfo = styled.p`
  font-size: 12px;
  margin: 10px auto 0 auto;
  padding: 3px 8px;
  border-radius: 3px;
  max-width: 250px;
  cursor: pointer;
  background-color: ${props => (props.$isCopied ? '#dcfce7' : `${Colors.grayscale.LineGreyLight}`)};
`;

export const CopiedText = styled.p`
  font-size: 12px;
`;
