import styled from "styled-components";

export const List = styled.ul`
  margin: 0;
  padding-inline-start: 24px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;

  ${({ $dir }) => `
    direction: ${$dir};
  `}
`;

export const BubbleContent = styled.div`
  background-color: #FDF7E3;
  padding: 8px;
  border-radius: 10px;
  word-break: break-all;
  color: #363D45;
`;

export const Line = styled.p`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

export const Info = styled.div`
  font-size: 12px;
  font-style: italic;
  color: #6b7178;
  margin-top: 4px;

  ${({ $dir }) => $dir === "rtl" ? `
    text-align: left;
  ` : `
    text-align: right;
  `}
`;
